import { Marquee, loop, LoopReturn } from 'dynamic-marquee';

export const marquee = () => {
  declare global {
    interface Window {
      m?: Marquee;
      l?: LoopReturn;
    }
  }

  const $marquee = document.getElementById('marquee')!;

  // if marquee element doesn't exist, return
  if (!$marquee) return;

  const marquee = (window.m = new Marquee($marquee, {
    rate: -100,
    startOnScreen: true, // start on screen
  }));

  // Donor data with unique SVG IDs and classes
  // Donor data with unique SVG references
  const donors = [
    {
      name: 'Addison Stickney',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Ann Mars',
      donation: 'In Memory Of',
      svg: 'compassion',
    },
    {
      name: 'Bruce Hedlund',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'John Lilyquist',
      donation: 'In Memory Of',
      svg: 'compassion',
    },
    {
      name: 'Kevin Hoene',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Marilyn Lundquist',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Maynard Solomonson',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Randy Hiti',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Ronald H. Tucker',
      donation: 'In Memory Of',
      svg: 'compassion',
    },
    {
      name: 'Rose Helin',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Sue Wischnewski',
      donation: 'In Memory Of',
      svg: 'compassion',
    },
    {
      name: 'Thomas E Stone',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Dexter Larsen',
      donation: 'In Memory Of',
      svg: 'hope',
    },
    {
      name: 'Bob and Kay Stevens',
      donation: 'In Honor Of',
      svg: 'hope',
    },
    {
      name: 'Fr. John Petrich',
      donation: 'In Honor Of',
      svg: 'hope',
    },
    {
      name: 'Pastor Jeanine Alexander',
      donation: 'In Honor Of',
      svg: 'hope',
    },
    {
      name: 'The Miner Family',
      donation: 'In Honor Of',
      svg: 'legacy',
    },
    {
      name: 'The Schelde Family',
      donation: 'In Honor Of',
      svg: 'hope',
    },
  ];

  const donorz = [
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
    {
      name: 'Taylor Bjork',
      donation: '$5000 for Bob',
      svg: `
        <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Dan Litman',
      donation: '$4000 for Alice',
      svg: `
        <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
          <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
          <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
          <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
          <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
        </svg>`,
    },
    {
      name: 'Bridget Baker',
      donation: '$3500 for Charlie',
      svg: `
        <svg id="svg-bridget" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
          <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
          <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
        </svg>`,
    },
    {
      name: 'Abby Miller',
      donation: '$3000 for Dave',
      svg: `
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><defs><style>.cls-1{fill:#ffc635;}</style></defs><path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/><path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/><path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/><path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/><path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/><path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/></svg>`,
    },
  ];

  // Generate donor markup
  const createDonorMarkup = (name: string, donation: string, svg: string): HTMLElement => {
    const $donorItem = document.createElement('div');
    $donorItem.className = 'donor-item';

    // Determine the appropriate SVG markup based on the svg value
    let svgMarkup = '';
    if (svg === 'hope') {
      svgMarkup = `
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <defs><style>.cls-1{fill:#ffc635;}</style></defs>
        <path class="cls-1" d="M439.03,255.64v102.31c0,33.97,27.3,61.51,60.97,61.51s60.97-27.54,60.97-61.51v-225.33c-67.34,0-121.94,55.09-121.94,123.02Z"/>
        <path class="cls-1" d="M560.97,744.36v-102.31c0-33.97-27.3-61.51-60.97-61.51s-60.97,27.54-60.97,61.51v225.33c67.34,0,121.94-55.09,121.94-123.02Z"/>
        <path class="cls-1" d="M257.89,430.62l88.61,51.16c29.42,16.98,66.92,7.11,83.76-22.05,16.83-29.16,6.63-66.57-22.78-83.56l-195.14-112.67c-33.67,58.32-13.27,133.15,45.57,167.12Z"/>
        <path class="cls-1" d="M742.11,569.38l-88.61-51.16c-29.42-16.98-66.92-7.11-83.76,22.05-16.83,29.16-6.63,66.57,22.78,83.56l195.14,112.67c33.67-58.32,13.27-133.15-45.57-167.12Z"/>
        <path class="cls-1" d="M318.86,674.98l88.61-51.16c29.42-16.98,39.62-54.4,22.78-83.56-16.83-29.16-54.34-39.03-83.76-22.05l-195.14,112.67c33.67,58.32,108.68,78.06,167.51,44.1Z"/>
        <path class="cls-1" d="M681.14,325.02l-88.61,51.16c-29.42,16.98-39.62,54.4-22.78,83.56,16.83,29.16,54.34,39.03,83.76,22.05l195.14-112.67c-33.67-58.32-108.68-78.06-167.51-44.1Z"/>
      </svg>`;
    } else if (svg === 'compassion') {
      svgMarkup = `
      <svg id="svg-dan" class="svg-pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <defs><style>.path-pink{fill:#ec6c9f;}</style></defs>
        <path class="path-pink" d="M500,620.18l-71.66,71.66c-40.35,40.35-40.64,105.51-.64,145.51,4.97,4.97,10.33,9.31,15.98,13.04c.46,.3,.92,.58,1.39,.87c.82,.52,1.64,1.05,2.48,1.54c15.57,9.29,33.11,14.05,50.74,14.32c.57,0,1.15,.03,1.72,.03c.57,0,1.15-.02,1.72-.03c17.63-.27,35.17-5.03,50.75-14.32c.82-.49,1.64-1.01,2.45-1.52c.47-.3,.95-.58,1.41-.89c5.65-3.73,11.01-8.07,15.98-13.04c40-40,39.71-105.15-.64-145.51l-71.66-71.66Z"/>
        <path class="path-pink" d="M446.85,473.84c.48.32.97.61,1.46.92.8.5,1.59,1.02,2.4,1.5,15.57,9.29,33.11,14.05,50.75,14.32c.57.01,1.15.03,1.72.03c.57,0,1.15-.02,1.72-.03c17.64-.27,35.17-5.03,50.75-14.32c.81-.48,1.6-.99,2.4-1.5c.49-.31.98-.6,1.46-.92,5.97-3.89,11.63-8.44,16.87-13.68l267.69-267.69c-80-80-210.31-79.42-291.01,1.28l-49.88,49.88-49.88-49.88c-80.71-80.71-211.01-81.28-291.01-1.28l267.69,267.69c5.24,5.24,10.9,9.79,16.87,13.68Z"/>
        <path class="path-pink" d="M383.19,503.2L115.5,235.51c-80,80-79.42,210.31,1.28,291.01l121.55,121.55c40.35,40.35,105.51,40.64,145.51.64,40-40,39.71-105.15-.64-145.51Z"/>
        <path class="path-pink" d="M884.5,235.51l-267.69,267.69c-40.35,40.35-40.64,105.51-.64,145.51,40,40,105.15,39.71,145.51-.64l121.55-121.55c80.71-80.71,81.28-211.01,1.28-291.01Z"/>
      </svg>`;
    } else if (svg === 'legacy') {
      svgMarkup = `
      <svg id="svg-taylor" class="svg-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <defs><style>.path-blue{fill:#53b6e7;}</style></defs>
        <path class="path-blue" d="M454.84,252.43c-22.26-61.77-81.08-105.79-149.96-105.51-87.71.35-158.54,72.39-158.18,160.88l2.38,573.98c146.25-.59,269.02-100.84,305.39-236.72,7.25-27.1,11.09-55.61,10.97-85.03l-1.08-253.51c-.08-18.99-3.44-37.2-9.52-54.08Z"/>
        <path class="path-blue" d="M842.04,304.99c-.36-88.48-71.77-159.95-159.48-159.59-68.88.28-127.34,44.77-149.1,106.72-5.95,16.93-9.16,35.16-9.08,54.16l1.08,253.51c.12,29.43,4.19,57.9,11.66,84.94,37.47,135.58,161.04,234.84,307.29,234.24l-2.38-573.98Z"/>
      </svg>`;
    }

    $donorItem.innerHTML = `
    <span>${donation}</span>
    <h4>${name}</h4>
    <div class="level">
      ${svgMarkup}
    </div>
  `;

    return $donorItem;
  };

  // Pass the rendered DOM elements to the marquee loop
  window.l = loop(
    marquee,
    donors.map(({ name, donation, svg }) => () => {
      const $donorMarkup = createDonorMarkup(name, donation, svg);
      const $wrapper = document.createElement('div');
      $wrapper.appendChild($donorMarkup);
      return $wrapper;
    }),
    () => {
      const $separator = document.createElement('div');
      $separator.className = 'separator';
      $separator.innerHTML = '&nbsp•&nbsp';
      return $separator;
    },
  );
};
