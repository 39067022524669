import { desktopMenu } from './desktop-menu';
import { gforms } from './gforms';
import { mobileMenu } from './mobile-menu';
import { carousel } from './carousel';
import { animations } from './animations';
import { marquee } from './donor-marquee';
import { gallerySlider } from './gallerySlider';

const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

// on dom content loaded
rdy(() => {
  desktopMenu();
  animations();
  mobileMenu();
  gallerySlider();
  carousel();
  marquee();
  gforms();
});
