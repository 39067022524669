import Swiper from 'swiper/bundle';

export const gallerySlider = (): void => {
  // Flag galleries that have captions
  document.querySelectorAll('.wp-block-gallery > figcaption').forEach((figcaption) => {
    const gallery = figcaption.closest('.wp-block-gallery');
    if (gallery) {
      gallery.classList.add('has-caption');
    }
  });

  // Gallery sliders
  document.querySelectorAll('.is-style-slider').forEach((block, i) => {
    // Remove all classes starting with "wp-" from the block
    block.className = block.className
      .split(' ')
      .filter((className) => !className.startsWith('wp-'))
      .join(' ');

    // Add new classes and structure for Swiper
    block.classList.add('wp-block-gallery', 'swiper');

    // Add `swiper-slide` class to children and wrap them in `.swiper-wrapper`
    const children = Array.from(block.children);
    const swiperWrapper = document.createElement('div');
    swiperWrapper.classList.add('swiper-wrapper');
    children.forEach((child) => {
      child.classList.add('swiper-slide');
      swiperWrapper.appendChild(child);
    });
    block.appendChild(swiperWrapper);

    // Add Swiper controls
    const pagination = document.createElement('div');
    pagination.classList.add('swiper-pagination');
    block.appendChild(pagination);

    const buttonPrev = document.createElement('div');
    buttonPrev.classList.add('swiper-button-prev', 'swiper-button');
    buttonPrev.innerHTML =
      '<?xml version="1.0" encoding="UTF-8"?><svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><path d="m477.91 600 16.77 19.602 181.37 211.6 46.051-39.203-164.6-192 164.6-192-46.051-39.203-181.37 211.6-16.77 19.602z" fill="#fff"/></svg>';
    block.appendChild(buttonPrev);

    const buttonNext = document.createElement('div');
    buttonNext.classList.add('swiper-button-next', 'swiper-button');
    buttonNext.innerHTML =
      '<?xml version="1.0" encoding="UTF-8"?><svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><path d="m722.1 600-16.766-19.602-181.37-211.6-46.051 39.203 164.61 192-164.61 192 46.051 39.203 181.37-211.6z" fill="#fff"/></svg>';
    block.appendChild(buttonNext);

    // Initialize Swiper
    new Swiper(block, {
      loop: true,
      slidesPerView: 1,
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        init: function () {
          console.log('initialized.');
        },
        imagesReady: function () {
          console.log('images ready.');
          setAlignFullCssVar();
        },
      },
    });
  });
};
