export const animations = () => {
  // use intersection observer to add animation class to elements with .animate class
  const animateElements = document.querySelectorAll('.animate, .fade');

  console.log('animations');

  if (!animateElements.length) return;

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-visible');
        observer.unobserve(entry.target);
      }
    });
  });

  animateElements.forEach((el) => {
    observer.observe(el);
  });
};
