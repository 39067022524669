export const gforms = () => {
  // wrap submit button
  const gformButtons = document.querySelectorAll('.gform_footer button');

  gformButtons.forEach((button) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('wp-block-button');
    button.parentNode?.insertBefore(wrapper, button);
    wrapper.appendChild(button);
  });

  // for each .gfield_error, add .interacted to parent after user interaction
  const addInteractedClass = (e) => {
    e.target.parentElement.classList.add('interacted');
    e.target.removeEventListener('input', addInteractedClass);
  };

  const errors = document.querySelectorAll('.gfield_error input, .gfield_error textarea, .gfield_error select');

  errors.forEach((el) => {
    el.addEventListener('input', addInteractedClass);
  });

  // on textarea blur, calculate height of textarea and set css var --textarea-height
  // const textareas = document.querySelectorAll('textarea');

  // const calcTextareaHeight = (e) => {
  //   //
  // };
};

export default gforms;
